import React from "react";
import { SurveySection } from "../../models/Survey";
import Section from "../Section/Section";

interface Props {
  content: SurveySection[];
}

const Sections = (props: Props) => {
  const { content } = props;
  return (
    <>
      {content.map(item => (
        <Section section={item} />
      ))}
    </>
  );
};

export default Sections;
