import React from "react";
import { Survey } from "./models/Survey";
import { HttpRequestStatus } from "./models/Http";
import apiClient from "./core/dataSources";
import SurveyIntro from "./components/SurveyIntro/SurveyIntro";
import { isComplete } from "./utils/validation";
import SurveyContent from "./components/Sections/Sections";
import { Application } from "./core/style";
import { Reset } from "styled-reset";
import MultiQuestionCard from "./components/MultiQuestionCard/MultiQuestionCard";
import { payload } from "./core/mock/validSurvey";

const getSurveyConfig = () => {
  const { location } = window;
  if (/.{8}-.{8}/.test(location.pathname)) {
    const [surveyId, responderId] = location.pathname
      .split("/")
      .join("")
      .split("-");
    return { surveyId, responderId };
  }
  if (location.pathname === "/demo") {
    return {
      surveyId: "demo",
      responderId: "demo"
    };
  }
  return null;
};

interface SurveyConfig {
  surveyId: string;
  responderId: string;
}

function App() {
  const [surveyConfig, setSurveyConfig] = React.useState<SurveyConfig | null>(
    null
  );
  const [survey, setSurvey] = React.useState<Survey | null>(null);
  const [surveyFetchStatus, setFetchState] = React.useState<HttpRequestStatus>(
    HttpRequestStatus.null
  );

  React.useEffect(() => {
    if (!surveyConfig) {
      setSurveyConfig(getSurveyConfig());
    }
  }, [window.location, surveyConfig]);

  React.useEffect(() => {
    if (surveyFetchStatus === HttpRequestStatus.null && surveyConfig) {
      if (surveyConfig.surveyId === "demo") {
        setSurvey(payload.survey);
        setFetchState(HttpRequestStatus.done);
        return;
      }
      setFetchState(HttpRequestStatus.loading);
      apiClient
        .getSurveyById(surveyConfig.surveyId, surveyConfig.responderId)
        .then(response => {
          setSurvey(response.data.survey);
          setFetchState(HttpRequestStatus.done);
        });
    }
  }, [surveyConfig]);

  return (
    <Application>
      <Reset />
      {isComplete(surveyFetchStatus) && [
        <SurveyIntro survey={survey!} />,
        <SurveyContent content={survey!.content.sections} />
      ]}
    </Application>
  );
}

export default App;
