import React, { useContext } from "react";
import { SurveyQuestionSet, SurveyQuestion } from "../../models/Survey";
import { Section, TextBlock } from "../../core/style";
import { Card, CardQuestion, CardHeader, CardContent, SetLabel } from "./style";
import { FiCheckCircle } from "react-icons/fi";
import { scaleContext } from "../Section/Section";
import { VerticalScale } from "../Scale/Scale";
import { Name } from "../Section/style";

interface Props {
  group: SurveyQuestionSet;
}

const QuestionCard = ({ questions }: { questions: SurveyQuestion[] }) => {
  const [selected, setSelected] = React.useState(0);
  const [clicked, setClicked] = React.useState<string[]>([]);

  const answerSelected = (id: string) => {
    setClicked(clicked.concat(id));
    return setTimeout(() => setSelected(selected + 1), 500);
  };

  return (
    <Card>
      {questions.map((question, idx) => (
        <CardQuestion
          onClick={() => setSelected(idx)}
          hasDivider={idx !== questions.length - 1}
        >
          <CardHeader checked={clicked.includes(question.id)} tabIndex={0}>
            <h4>{question.question.en}</h4>
            <FiCheckCircle />
          </CardHeader>
          {idx === selected && (
            <CardContent>
              <VerticalScale callback={() => answerSelected(question.id)} />
            </CardContent>
          )}
        </CardQuestion>
      ))}
    </Card>
  );
};

const MultiQuestionCard = (props: Props) => {
  const { group } = props;
  return (
    <>
      <SetLabel>{group.name.en}</SetLabel>
      <TextBlock>{group.introduction.en}</TextBlock>
      <QuestionCard questions={group.questions} />
    </>
  );
};

export default MultiQuestionCard;
