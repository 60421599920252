import styled from "styled-components";

export const Container = styled.section`
  padding: 3rem;

  @media (max-width: 600px), (max-device-width: 600px) {
    padding: 0 1rem;
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: 600;
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  padding: 1rem 0rem;
`;
