import React from "react";
import { format } from "date-fns";
import { Survey } from "../../models/Survey";
import { Container, Title, Text } from "./style";

interface Props {
  survey: Survey;
}

const SurveyIntro = (props: Props) => {
  const { content, createdAt } = props.survey;
  const { title, introduction } = content;

  return (
    <Container>
      <Title>
        {title} - {createdAt && format(new Date(createdAt), "d.M.yyyy")}
      </Title>
      <Text>{introduction.en}</Text>
    </Container>
  );
};

export default SurveyIntro;
