import styled, { StyledComponent, AnyStyledComponent } from "styled-components";

export const ScaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;

  @media (max-width: 600px), (max-device-width: 600px) {
    padding: 2rem 1rem;
  }
`;

export const ScaleItem: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: ${(props: any) => (props.reverse ? "row-reverse" : "row")};
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @media (max-width: 600px), (max-device-width: 600px) {
    flex-direction: row;
  }
`;

export const ScaleRadio = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  margin: 1rem;
`;

export const ScaleLabel = styled.label`
  font-weight: 300;
  font-size: 1.4rem;
  color: #030301;
`;

export const ScaleValue = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  position: relative;
  cursor: pointer;

  > label {
    margin: 1rem;
    padding: 1rem;
    margin-top: 0;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: 500;
    line-height: 1.2;

    @media (max-width: 600px), (max-device-width: 600px) {
      padding: 0;
      margin: 0.5rem;
      font-size: 0.8rem;
    }
  }

  > input {
    height: 50px;
  }

  :not(:last-child):before {
    content: "";
    width: 100%;
    left: 50%;
    position: absolute;
    background: #7ea8be;
    height: 6px;
    bottom: 22px;
  }

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    background: #7ea8be;
    position: absolute;
    bottom: 12px;
    border-radius: 50%;
    border: 3px solid #7ea8be;
  }

  :hover:after {
    background: #28536b;
    border-color: #28536b;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  :focus:after {
    border-color: #28536b;
  }
`;
