import { Survey } from "../../models/Survey";

export const payload: { survey: Survey } = {
  survey: {
    surveyId: "3777b046-bfee-417e-950a-80e88a633e1f",
    createdAt: new Date("2020-10-24T17:36:39.000Z"),
    surveyBaseId: "b319416b-01eb-4f61-84e2-e3dcbbffe8b8",
    responderId: "d72823d3-6211-4695-9d95-b520f50e802d",
    content: {
      title: "Remote Work",
      sections: [
        {
          name: {
            en: "Blalab Happiness Index",
            fi: "Blalabin onnellisuusindeksi",
            se: "Blalabs lycko index"
          },
          scale: [
            {
              label: {
                en: "Strongly disagree",
                fi: "T\u00e4ysin eri mielt\u00e4",
                se: "Inst\u00e4mmer inte alls"
              },
              value: 1
            },
            {
              label: {
                en: "Disagree",
                fi: "Eri mielt\u00e4",
                se: "Inst\u00e4mmer inte"
              },
              value: 2
            },
            {
              label: { en: "Neutral", fi: "Neutraali", se: "Neutral" },
              value: 3
            },
            {
              label: {
                en: "Agree",
                fi: "Samaa mielt\u00e4",
                se: "Inst\u00e4mmer"
              },
              value: 4
            },
            {
              label: {
                en: "Strongly agree",
                fi: "Eritt\u00e4in paljon samaa mielt\u00e4",
                se: "Inst\u00e4mmer helt"
              },
              value: 5
            }
          ],
          title: {
            en: "How you are feeling at work.",
            fi: "Milt\u00e4 sinusta tuntuu t\u00f6iss\u00e4.",
            se: "Hur du k\u00e4nner dig p\u00e5 jobbet."
          },
          questionSet: [
            {
              name: {
                en: "Well-being",
                fi: "Hyvinvointi",
                se: "V\u00e4lm\u00e5ende"
              },
              questions: [
                {
                  id: "4dae8dd8-1",
                  question: {
                    en: "I am satisfied with my work-life balance.",
                    fi:
                      "Olen tyytyv\u00e4inen ty\u00f6el\u00e4m\u00e4n ja muun el\u00e4m\u00e4n tasapainoon.",
                    se:
                      "Jag \u00e4r n\u00f6jd med balansen mellan arbete och fritid."
                  }
                },
                {
                  id: "4dae8dd8-2",
                  question: {
                    en: "My overall health is well supported in my work.",
                    fi:
                      "Kokonaisvaltaista terveytt\u00e4ni tuetaan hyvin ty\u00f6ss\u00e4ni.",
                    se: "Min h\u00e4lsa v\u00e4l st\u00f6ds i mitt arbete."
                  }
                },
                {
                  id: "4dae8dd8-3",
                  question: {
                    en:
                      "I believe I will be in good physical condition at work 5 years from now.",
                    fi:
                      "Uskon, ett\u00e4 olen hyv\u00e4ss\u00e4 fyysisess\u00e4 kunnossa ty\u00f6ss\u00e4ni viiden vuoden p\u00e4\u00e4st\u00e4.",
                    se:
                      "Jag tror att jag kommer att vara i god fysisk kondition i mitt arbete om 5 \u00e5r"
                  }
                }
              ],
              introduction: {
                en:
                  "These questions relate to your well-being at work. To what extent do you agree with the following statements?",
                fi:
                  "N\u00e4m\u00e4 kysymykset liittyv\u00e4t hyvinvointiisi ty\u00f6ss\u00e4. Miss\u00e4 m\u00e4\u00e4rin olet samaa mielt\u00e4 seuraavien v\u00e4itt\u00e4mien kanssa?",
                se:
                  "Dessa fr\u00e5gor handlar om ditt v\u00e4lm\u00e5ende p\u00e5 jobbet. Hur v\u00e4l inst\u00e4mmer du med f\u00f6ljande p\u00e5st\u00e5enden?"
              }
            },
            {
              name: { en: "Commitment", fi: "Sitoutuminen", se: "Engagemang" },
              questions: [
                {
                  id: "4dae8dd8-4",
                  question: {
                    en: "My work is important to me.",
                    fi: "Ty\u00f6ni on minulle t\u00e4rke\u00e4\u00e4.",
                    se: "Mitt jobb \u00e4r viktigt f\u00f6r mig."
                  }
                },
                {
                  id: "4dae8dd8-5",
                  question: {
                    en: "I want to continue working with what I do.",
                    fi:
                      "Haluan jatkaa ty\u00f6skentely\u00e4 niiden asioiden parissa, joita nyt teen.",
                    se: "Mitt jobb \u00e4r viktigt f\u00f6r mig."
                  }
                },
                {
                  id: "4dae8dd8-6",
                  question: {
                    en: "I usually speak positively about my work.",
                    fi:
                      "Puhun tavallisesti my\u00f6nteisesti ty\u00f6st\u00e4ni.",
                    se: "Jag pratar oftast positivt om mitt jobb."
                  }
                }
              ],
              introduction: {
                en:
                  "These questions relate to your commitment at work. To what extent do you agree with the following statements?",
                fi:
                  "N\u00e4m\u00e4 kysymykset liittyv\u00e4t ty\u00f6h\u00f6n sitoutuneisuuteesi. Miss\u00e4 m\u00e4\u00e4rin olet samaa mielt\u00e4 seuraavien v\u00e4itt\u00e4mien kanssa?",
                se:
                  "Jag vill forts\u00e4tta jobba i den h\u00e4r organisationen."
              }
            },
            {
              name: { en: "Enjoyment", fi: "Viihtyminen", se: "Trivsel" },
              questions: [
                {
                  id: "4dae8dd8-7",
                  question: {
                    en:
                      "In the morning I look forward to start the day at work.",
                    fi:
                      "Aamuisin odotan, ett\u00e4 p\u00e4\u00e4sen aloittamaan p\u00e4iv\u00e4n ty\u00f6t.",
                    se:
                      "P\u00e5 morgonen ser jag fram emot att b\u00f6rja min dag p\u00e5 jobbet."
                  }
                },
                {
                  id: "4dae8dd8-8",
                  question: {
                    en: "Sometimes the working day passes too quickly.",
                    fi: "Joskus ty\u00f6p\u00e4iv\u00e4 kuluu liian nopeasti.",
                    se: "Ibland g\u00e5r arbetsdagen f\u00f6r snabbt."
                  }
                },
                {
                  id: "4dae8dd8-9",
                  question: {
                    en:
                      "When returning from work usually I feel satisfied about the work day.",
                    fi:
                      "Kun palaan t\u00f6ist\u00e4 kotiin, olen yleens\u00e4 tyytyv\u00e4inen ty\u00f6p\u00e4iv\u00e4\u00e4ni.",
                    se:
                      "N\u00e4r jag kommer hem fr\u00e5n jobbet \u00e4r jag oftast n\u00f6jd med min dag."
                  }
                }
              ],
              introduction: {
                en:
                  "These questions relate to how much you enjoy your work. To what extent do you agree with the following statements?",
                fi:
                  "N\u00e4m\u00e4 kysymykset liittyv\u00e4t siihen, miten paljon nautit ty\u00f6st\u00e4si. Miss\u00e4 m\u00e4\u00e4rin olet samaa mielt\u00e4 seuraavien v\u00e4itt\u00e4mien kanssa?",
                se:
                  "Dessa fr\u00e5gor handlar om hur v\u00e4l du trivs p\u00e5 jobbet. Hur v\u00e4l inst\u00e4mmer du med f\u00f6ljande p\u00e5st\u00e5enden"
              }
            }
          ],
          introduction: { en: "", fi: "", se: "" }
        },
        {
          name: {
            en: "Remote work",
            fi: "Et\u00e4ty\u00f6",
            se: "Fj\u00e4rrarbete"
          },
          scale: [
            {
              label: {
                en: "Strongly disagree",
                fi: "T\u00e4ysin eri mielt\u00e4",
                se: "Inst\u00e4mmer inte alls"
              },
              value: 1
            },
            {
              label: {
                en: "Disagree",
                fi: "Eri mielt\u00e4",
                se: "Inst\u00e4mmer inte"
              },
              value: 2
            },
            {
              label: { en: "Neutral", fi: "Neutraali", se: "Neutral" },
              value: 3
            },
            {
              label: {
                en: "Agree",
                fi: "Samaa mielt\u00e4",
                se: "Inst\u00e4mmer"
              },
              value: 4
            },
            {
              label: {
                en: "Strongly agree",
                fi: "Eritt\u00e4in paljon samaa mielt\u00e4",
                se: "Inst\u00e4mmer helt"
              },
              value: 5
            }
          ],
          title: {
            en:
              "This module captures how well you think that various things are working in your team when working remotely. Think about how you experience things when not being present face-to-face with your colleagues.",
            fi:
              "T\u00e4m\u00e4 osio tutkii sit\u00e4, miten hyvin sinun mielest\u00e4si eri asiat toimivat tiimisi tehdess\u00e4 et\u00e4ty\u00f6t\u00e4. Pohdi, mik\u00e4 on oma kokemuksesi n\u00e4ist\u00e4 asioista silloin, kun et ole tiimikaveriesi kanssa fyysisesti samassa paikassa.",
            se:
              "Den h\u00e4r modulen handlar om hur v\u00e4l du tror att saker fungerar i ditt team n\u00e4r du arbetar p\u00e5 distans. T\u00e4nk p\u00e5 hur du upplever saker n\u00e4r du inte \u00e4r p\u00e5 plats med dina kollegor."
          },
          questionSet: [
            {
              name: { en: "Tools", fi: "Ty\u00f6kalut", se: "Arbetsredskap" },
              questions: [
                {
                  id: "ca055407-1",
                  question: {
                    en:
                      "We have sufficient tools to easily communicate with others.",
                    fi:
                      "Ty\u00f6v\u00e4lineemme tekev\u00e4t viestinn\u00e4n muiden kanssa helpoksi.",
                    se:
                      "Vi har tillr\u00e4ckliga verktyg f\u00f6r att enkelt kommunicera med andra."
                  }
                },
                {
                  id: "ca055407-2",
                  question: {
                    en: "Our tools support continuous collaboration well.",
                    fi:
                      "Ty\u00f6v\u00e4lineemme tukevat jatkuvaa yhteisty\u00f6t\u00e4 hyvin.",
                    se:
                      "V\u00e5ra verktyg st\u00f6djer kontinuerlig samverkan v\u00e4l."
                  }
                },
                {
                  id: "ca055407-3",
                  question: {
                    en: "Our tools help us to be productive.",
                    fi:
                      "Ty\u00f6v\u00e4lineemme yll\u00e4pit\u00e4v\u00e4t tuottavuuttamme.",
                    se:
                      "V\u00e5ra verktyg hj\u00e4lper oss att vara produktiva."
                  }
                }
              ],
              introduction: {
                en:
                  "These questions relate to how well you think your work tools support your work when working remotely. To what extent do you agree with the following statements?",
                fi:
                  "N\u00e4m\u00e4 kysymykset liittyv\u00e4t siihen, miten hyvin ty\u00f6v\u00e4lineesi tukevat et\u00e4ty\u00f6skentely\u00e4. Miss\u00e4 m\u00e4\u00e4rin olet samaa mielt\u00e4 seuraavien v\u00e4itt\u00e4mien kanssa?",
                se:
                  "Dessa fr\u00e5gor handlar om hur v\u00e4l du tycker att dina arbetsverktyg st\u00f6djer ditt arbete n\u00e4r du arbetar p\u00e5 distans. Hur v\u00e4l inst\u00e4mmer du med f\u00f6ljande p\u00e5st\u00e5enden?"
              }
            },
            {
              name: {
                en: "Remote communication",
                fi: "Et\u00e4viestint\u00e4",
                se: "Kommunikation p\u00e5 distans"
              },
              questions: [
                {
                  id: "ca055407-4",
                  question: {
                    en: "We communicate a lot with each other online.",
                    fi: "Viestimme paljon toistemme kanssa verkossa.",
                    se: "Vi kommunicerar mycket med varandra online."
                  }
                },
                {
                  id: "ca055407-5",
                  question: {
                    en: "People in this team are easily reachable online.",
                    fi: "Tavoitamme tiimikaverimme helposti verkon kautta.",
                    se:
                      "Personerna detta team \u00e4r l\u00e4ttillg\u00e4ngliga online."
                  }
                },
                {
                  id: "ca055407-6",
                  question: {
                    en:
                      "Our digital channels provide us with all needed information.",
                    fi:
                      "Saamme kaiken tarvitsemamme tiedon digitaalisten kanavien kautta.",
                    se:
                      "V\u00e5ra digitala kanaler ger oss all n\u00f6dv\u00e4ndig information."
                  }
                }
              ],
              introduction: {
                en:
                  "These questions relate to how well you think your team communicates remotely. To what extent do you agree with the following statements?",
                fi:
                  "N\u00e4m\u00e4 kysymykset liittyv\u00e4t siihen, miten hyvin tiimisi kommunikoi et\u00e4ty\u00f6skennelless\u00e4\u00e4n. Miss\u00e4 m\u00e4\u00e4rin olet samaa mielt\u00e4 seuraavien v\u00e4itt\u00e4mien kanssa?",
                se:
                  "Dessa fr\u00e5gor handlar om hur v\u00e4l du tycker att ditt team kommuniserar p\u00e5 distans. Hur v\u00e4l inst\u00e4mmer du med f\u00f6ljande p\u00e5st\u00e5enden?"
              }
            },
            {
              name: {
                en: "Connectedness",
                fi: "Yhteyden tunne",
                se: "Samh\u00f6righet"
              },
              questions: [
                {
                  id: "ca055407-7",
                  question: {
                    en: "When working remotely we feel like unified team.",
                    fi:
                      "Koemme et\u00e4ty\u00f6t\u00e4 tehdess\u00e4mme olevamme yhten\u00e4inen tiimi.",
                    se:
                      "N\u00e4r vi arbetar p\u00e5 distans k\u00e4nner vi oss som ett enhetligt team."
                  }
                },
                {
                  id: "ca055407-8",
                  question: {
                    en: "We reach out to each other for support.",
                    fi:
                      "Olemme yhteydess\u00e4 toisiimme, kun tarvitsemme tukea.",
                    se:
                      "Vi n\u00e5r ut till varandra f\u00f6r att f\u00e5 st\u00f6d."
                  }
                },
                {
                  id: "ca055407-9",
                  question: {
                    en:
                      "I rarely feel alone or isolated when working remotely.",
                    fi:
                      "Koen harvoin olevani yksin tai erityksiss\u00e4 tehdess\u00e4ni et\u00e4ty\u00f6t\u00e4.",
                    se:
                      "Jag k\u00e4nner s\u00e4llan ensam eller isolerad n\u00e4r jag arbetar p\u00e5 distans."
                  }
                }
              ],
              introduction: {
                en:
                  "These questions relate to how well you think your team members feel connected to eachother. To what extent do you agree with the following statements?",
                fi:
                  "N\u00e4m\u00e4 kysymyket liittyv\u00e4t siihen, miten vahvassa yhteydess\u00e4 tiimisi j\u00e4senet ovat mielest\u00e4si toisiinsa. Miss\u00e4 m\u00e4\u00e4rin olet samaa mielt\u00e4 seuraavien v\u00e4itt\u00e4mien kanssa?",
                se:
                  "Dessa fr\u00e5gor h\u00e4nf\u00f6r sig till hur v\u00e4l du tycker dina teammedlemmar k\u00e4nner sig anslutna till varandra. Hur v\u00e4l inst\u00e4mmer du med f\u00f6ljande p\u00e5st\u00e5enden?"
              }
            },
            {
              name: {
                en: "Coordination",
                fi: "Koordinaatio",
                se: "Koordinering"
              },
              questions: [
                {
                  id: "ca055407-10",
                  question: {
                    en: "I'm rarely surprised with things I didn't know of.",
                    fi: "Koen harvoin tulevani yll\u00e4tetyksi.",
                    se:
                      "Jag blir s\u00e4llan \u00f6verraskad med saker jag inte visste om."
                  }
                },
                {
                  id: "ca055407-11",
                  question: {
                    en: "Our roles and responsibilities are very clear.",
                    fi: "Roolit ja vastuut ovat todella selkeit\u00e4.",
                    se: "V\u00e5ra roller och ansvar \u00e4r mycket tydliga."
                  }
                },
                {
                  id: "ca055407-12",
                  question: {
                    en: "This team is well coordinated.",
                    fi: "T\u00e4m\u00e4 tiimi on hyvin koordinoitu.",
                    se: "Detta team \u00e4r v\u00e4l samordnat."
                  }
                }
              ],
              introduction: {
                en:
                  "These questions relate to how coordinated you think your team is. To what extent do you agree with the following statements?",
                fi:
                  "N\u00e4m\u00e4 kysymykset liittyv\u00e4t siihen, miten koordinoitu tiimisi mielest\u00e4si on. Miss\u00e4 m\u00e4\u00e4rin olet samaa mielt\u00e4 seuraavien v\u00e4itt\u00e4mien kanssa?",
                se:
                  "Dessa fr\u00e5gor handlar om hur v\u00e4l koordinerat du tycker att ditt team \u00e4r. Hur v\u00e4l inst\u00e4mmer du med f\u00f6ljande p\u00e5st\u00e5enden?"
              }
            },
            {
              name: {
                en: "Team purpose",
                fi: "Tiimin merkitys",
                se: "Teamets syfte"
              },
              questions: [
                {
                  id: "ca055407-13",
                  question: {
                    en: "We understand the purpose of this team very well.",
                    fi: "Ymm\u00e4rr\u00e4mme tiimin tarkoituksen hyvin.",
                    se: "Vi vet vad vi vill uppn\u00e5 p\u00e5 l\u00e5ng sikt."
                  }
                },
                {
                  id: "ca055407-14",
                  question: {
                    en: "We know how we can contribute to the bigger picture.",
                    fi: "Tied\u00e4mme miten voimme edesauttaa isompaa kuvaa.",
                    se:
                      "Vi vet hur vi kan bidra till en st\u00f6rre m\u00e5lbild."
                  }
                },
                {
                  id: "ca055407-15",
                  question: {
                    en: "We know exactly how to make progress together.",
                    fi:
                      "Tied\u00e4mme t\u00e4sm\u00e4lleen mit\u00e4 tehd\u00e4 p\u00e4\u00e4st\u00e4ksemme eteenp\u00e4in tiimin\u00e4.",
                    se:
                      "Vi vet exakt hur vi kan g\u00f6ra framsteg tillsammans."
                  }
                }
              ],
              introduction: {
                en:
                  "These questions relate to how clear the purpose of the team is. To what extent do you agree with the following statements?",
                fi:
                  "N\u00e4m\u00e4 kysymykset liittyv\u00e4t siihen, miten selke\u00e4 tiimin merkitys on. Miss\u00e4 m\u00e4\u00e4rin olet samaa mielt\u00e4 seuraavien v\u00e4itt\u00e4mien kanssa?",
                se:
                  "Dessa fr\u00e5gor handlar om hur tydlig meningen med teamet \u00e4r. Hur v\u00e4l inst\u00e4mmer du med f\u00f6ljande p\u00e5st\u00e5enden?"
              }
            }
          ],
          introduction: { en: "", fi: "", se: "" }
        }
      ],
      introduction: {
        en:
          "Great to see you here! Now you can share your wisdom! In this survey you will respond to questions about how you feel things run in your remote team. Answering these questions will help everyone have more fun and get better results together, even when working remotely.",
        fi:
          "Mahtavaa n\u00e4hd\u00e4 sinut t\u00e4\u00e4ll\u00e4! Sana on nyt vapaa. Kyselyss\u00e4 p\u00e4\u00e4set vastaamaan kysymyksiin liittyen siihen, miten sin\u00e4 ja tiimisi voitte et\u00e4ty\u00f6skennelless\u00e4nne. Kyselyn muutamat kysymykset liittyv\u00e4t tiimisi hyvinvointiin sen olessa et\u00e4ty\u00f6moodissa. Kysely auttaa koko tiimi\u00e4 tekem\u00e4\u00e4n ty\u00f6st\u00e4 hauskempaa sek\u00e4 saavuttamaan enemm\u00e4n yhdess\u00e4!",
        se:
          "Fantastiskt att se dig h\u00e4r! Nu kan du dela din visdom! I denna unders\u00f6kning kommer du att svara p\u00e5 fr\u00e5gor om hur du k\u00e4nner att saker fungerar i ditt team d\u00e5 ni arbetar p\u00e5 distans. Fr\u00e5gorna handlar b\u00e5de om hur det g\u00e5r f\u00f6r dig och ditt team d\u00e5 ni ajobabr p\u00e5 distans. Att svara p\u00e5 dessa fr\u00e5gor hj\u00e4lper alla att m\u00e5 b\u00e4ttre och uppn\u00e5 mer tillsammans."
      }
    }
  }
};
