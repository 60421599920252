import { get, post } from "./axiosClient";
import { Survey, AnsweredSurvey } from "../models/Survey";
import { apiRootUrl } from "../config";

const apiClient = {
  getSurveyById: (surveyId: string, responderId: string) =>
    get<{ survey: Survey }>(
      `${apiRootUrl}/v1/survey?sid=${surveyId}&rid=${responderId}`
    ),

  postAnsweredSurvey: (survey: AnsweredSurvey) => {
    const surveyData = {
      surveyId: survey.surveyId,
      responderId: survey.responderId,
      answers: survey.answers
    };
    return post(`${apiRootUrl}/v1/survey/`, surveyData);
  }
};

export default apiClient;
