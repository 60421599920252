import React, { useContext } from "react";
import { scaleContext } from "../Section/Section";
import {
  ScaleContainer,
  ScaleRadio,
  ScaleItem,
  ScaleLabel,
  ScaleValue
} from "./style";

interface Props {
  questionId?: string;
  reverse?: boolean;
  callback: () => void;
}

const Component = (props: Props) => {
  const { questionId, reverse, callback } = props;
  const { scale } = useContext(scaleContext);

  const scaleClick = () => {
    setTimeout(callback, 500);
  };

  return (
    <ScaleContainer>
      {scale.map((item, idx) => (
        <ScaleItem reverse={reverse}>
          <ScaleRadio onClick={() => scaleClick()} type="checkbox" />
          <ScaleLabel>{item.label.en}</ScaleLabel>
        </ScaleItem>
      ))}
    </ScaleContainer>
  );
};

export const VerticalScale = (props: Props) => {
  const { scale } = useContext(scaleContext);
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {scale.map(item => (
        <ScaleValue tabIndex={0} onClick={() => props.callback()}>
          <span>{item.label.en}</span>
          <input type="radio" style={{ visibility: "hidden" }} />
        </ScaleValue>
      ))}
    </div>
  );
};

export default Component;
