import React from "react";
import { SurveySection, Scale } from "../../models/Survey";
import { Name } from "./style";
import QuestionGroup from "../QuestionGroup/QuestionGroup";
import { TextBlock, Section } from "../../core/style";
import MultiQuestionCard from "../MultiQuestionCard/MultiQuestionCard";

interface ScaleContext {
  scale: Scale[];
}

export const scaleContext = React.createContext<ScaleContext>({
  scale: []
});

const { Provider } = scaleContext;

interface Props {
  section: SurveySection;
}

const Component = (props: Props) => {
  const { section } = props;
  const [visibleGroup, setVisibleGroup] = React.useState(0);

  const displayNextGroup = (nextIndex: number) => {
    setVisibleGroup(nextIndex);
  };

  return (
    <Section key={section.name.en}>
      <Name>{section.name.en}</Name>
      <TextBlock>{section.introduction.en}</TextBlock>
      <Provider value={{ scale: section.scale }}>
        {section.questionSet.map(item => (
          <MultiQuestionCard group={item} />
        ))}
      </Provider>
    </Section>
  );
};

export default Component;
