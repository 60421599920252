import axios, { AxiosResponse, Method, AxiosError } from "axios";

function getConfig(method: Method) {
  return {
    method,
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
      "Content-Type": "application/json;charset=utf-8"
    }
  };
}

const handleError = (err: AxiosError) => {
  const { response, request } = err;
  if (response) {
    const message = response.data.description;
    throw new Error(message);
  }
  if (request) {
    const message = request.data.description;
    throw new Error(message);
  }
  throw err;
};

export function get<T>(url: string) {
  return axios
    .get(url, getConfig("get"))
    .then((data: AxiosResponse<T>) => {
      return data;
    })
    .catch(handleError);
}

export function axiosDelete(url: string) {
  return axios
    .delete(url, getConfig("delete"))
    .then((response: AxiosResponse) => response)
    .catch(handleError);
}

export function post(url: string, body: any) {
  return axios
    .post(url, body, getConfig("post"))
    .then((response: AxiosResponse) => response)
    .catch(handleError);
}
