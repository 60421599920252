import styled, { AnyStyledComponent } from "styled-components";

export const Card = styled.div`
  margin: 1rem 0rem;
  border-radius: 1rem;
  width: 100%;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  overflow: hidden;

  background: #f6f0ed;

  box-shadow: 5px 5px 15px #e7e2df, -5px -5px 15px #fffefb;
  * {
    transition: all 0.5s ease-in-out;
  }
`;

export const CardQuestion: AnyStyledComponent = styled.div`
  border-bottom: ${(props: any) =>
    props.hasDivider ? ".5px solid rgba(0,0,0,0.12)" : "none"};

  :hover {
    background: rgba(246, 240, 237, 0.5);
    cursor: pointer;
  }

  > h4 {
  }
`;

export const CardHeader: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;

  > h4 {
    flex: 1;
  }

  :focus {
    background: #000;
    > * {
      color: white;
    }
  }

  > svg {
    color: ${(props: any) =>
      props.checked ? "#28536B" : "rgba(0, 0, 0, 0.18)"};
    margin: 0.5rem;
  }
`;

export const CardContent = styled.div`
  padding: 1rem;
`;

export const SetLabel = styled.h3`
  margin-top: 1.5rem;
  font-size: 1.4rem;
  padding: 1rem 0.5rem;
  font-weight: 500;
`;
